.root{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    max-width: 335px;
    height: 100vh;
    justify-content: center;
    gap: 24px;
    &__button{
        border: none;
        color: white;
        display: flex;
        width: 100%;
        padding: 15px 0px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 12px;
        background: var(--Buttons-Gradient, linear-gradient(100deg, #FB26FF -55.19%, #8A24FF 55.43%, #8424FF 97.06%));  
        text-decoration: none;
        color: #FFF;
        font-family: Nunito;
        font-size: 16px;
        font-weight: 700;
}
}